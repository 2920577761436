const boatsetterPaths = {
  aboutUs: '/about',
  account: '/account_settings',
  auth: {
    root: '/a/auth',
    verifyForgotPassword: '/a/auth/forgot-password/verify',
    completeAccount: '/a/auth/complete-account',
  },
  bachelorettePartyBoatRentals: '/bachelorette-party-boat-rentals',
  bachelorPartyBoatRentals: '/bachelor-party-boat-rentals',
  becomeACaptain: '/captains',
  blog: '/boating-resources',
  boatGuides: '/boat-rentals',
  boatRentals: '/boat-rentals',
  bookings: '/bookings',
  captainDashboard: '/captain',
  careers: '/jobs',
  catamaranRentals: '/catamaran-rentals',
  celebrationsSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=on&activities=celebrating',
  centerConsoleRentals: '/center-console-rentals',
  communityImpact: '/community-impact',
  cruisingSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=off&activities=cruising',
  editListing: '/a/boats',
  fishingBoatRentals: '/fishing-boat-rentals',
  fishingCharters: '/fishing-charters',
  fishingSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=on&activities=fishing',
  insuranceAndSafety: '/legal/insurance-policy',
  jetSkis: '/jet-ski-rentals',
  listBoat: '/a/list-boat',
  logIn: '/a/login',
  logOut: '/a/logout',
  luxuryYachtCharters: '/luxury-yacht-charters',
  luxuryYachtSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=map&near=United%20States&area=&page=1&ne_lat=55.76960667444207&ne_lng=-72.91667383750001&sw_lat=-7.723264717141288&sw_lng=-122.92643946250007&zoom_level=4&map_toggle=off&length_min=35&boat_category=mega_yacht&boat_category=motor_yacht&price_min=750',
  myBoats: '/manage_boats',
  newCollection: '/new',
  ownerDashboard: '/owner',
  partyBoatRentals: '/party-boat-rentals',
  pontoonBoatRentals: '/pontoon-boat-rentals',
  pontoonSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=off&boat_category=pontoon',
  press: '/press',
  renterDashboard: '/account_settings',
  rulesAndRegulations: '/regulations',
  sailboatRentals: '/sailboat-rentals',
  sailingSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=on&activities=sailing',
  search: '/a/boat-rentals/search',
  signUp: '/a/signup',
  snorkelingSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United%20States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=on&features=snorkeling_gear',
  summerCollection: '/summer_collection',
  uniqueFinds: '/summer_collection',
  waterSportsSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=search&near=United+States&area=&page=1&ne_lat=72.7087158&ne_lng=-66.3193754&sw_lat=15.7760139&sw_lng=-173.2992296&zoom_level=11&map_toggle=on&activities=watersports',
  whyList: '/why-list',
  yachtRentals: '/yacht-rentals',
  yachtSearch:
    '/a/boat-rentals/search?sort_by=recommended&per_page=18&search_mode=&action=map&near=United%20States&area=&page=1&ne_lat=55.76960667444207&ne_lng=-72.91667383750001&sw_lat=-7.723264717141288&sw_lng=-122.92643946250007&zoom_level=4&map_toggle=off&length_min=35&boat_category=mega_yacht&boat_category=motor_yacht&price_min=100&price_max=950',
  locations: {
    austin: '/boat-rentals/austin--tx--united-states',
    chicago: '/boat-rentals/chicago--il--united-states',
    destin: '/boat-rentals/destin--fl--united-states',
    fortLauderdale: '/boat-rentals/fort-lauderdale--fl--united-states',
    global: '/global',
    losAngeles: '/boat-rentals/los-angeles--ca--united-states',
    miami: '/boat-rentals/miami--fl--united-states',
    miamiBeach: '/boat-rentals/miami-beach--fl--united-states',
    newYorkCity: '/boat-rentals/new-york--ny--united-states',
    sanDiego: '/boat-rentals/san-diego--ca--united-states',
    sanFrancisco: '/boat-rentals/san-francisco--ca--united-states',
    sarasota: '/boat-rentals/sarasota--fl--united-states',
    seattle: '/boat-rentals/seattle--wa--united-states',
    tampaBay: '/boat-rentals/tampa--fl--united-states',
    washingtonDC: '/boat-rentals/washington--dc--united-states',

    lakeTravis: '/boat-rentals/lake-travis--tx--united-states',
    lakeTahoe: '/boat-rentals/lake-tahoe--united-states',
    lakeNorman: '/boat-rentals/lake-norman--nc--united-states',
    lakeLanier: '/boat-rentals/lake-lanier--ga--united-states',
    lakeGeorge: '/boat-rentals/lake-george--ny--united-states',
    lakeOfTheOzarks: '/boat-rentals/lake-of-the-ozarks--mo--united-states',
    lakeHavasu: '/boat-rentals/lake-havasu--united-states',
    lakeMead: '/boat-rentals/lake-mead--united-states',
    lakePowell: '/boat-rentals/lake-powell--united-states',
    lakeConroe: '/boat-rentals/lake-conroe--tx--united-states',
  },
  accountSettings: {
    password: {
      edit: '/account_settings/password/edit',
    },
  },
};

export const boatsetterSocials = {
  facebook: 'ttps://www.facebook.com/Boatsetter',
  instagram: 'https://www.instagram.com/Boatsetter/',
  pinterest: 'https://www.pinterest.com/Boatsetter1/_saved/',
  tiktok: 'https://www.tiktok.com/@boatsetter',
  twitter: 'https://twitter.com/Boatsetter',
  youtube: 'https://www.youtube.com/c/Boatsetter',
};

export const boatsetterFlags = {
  clerkSignup: 'clerk_signup',
};

export default boatsetterPaths;
